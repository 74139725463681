<template>
  <div>
    <div class="row">
      <div class="col-12">
        <router-link v-if="business"
            :to="{
                        name: 'agency.campaign-leads',
                        params: { id: business.search_id },
                      }"
            class="btn btn-link mr-5 text-success font-weight-bold d-flex align-items-center"
        >
          <Feather class="icon-sm" type="chevrons-left" /> Back
        </router-link>
        <div class="card rounded-lg">
          <div class="card-body">
            <div v-if="!loadingBusiness">
              <ValidationObserver
                v-if="business"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateBusiness)"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <TextInput
                          v-model="business.business_name"
                          name="business_name"
                          rules="required"
                          label="Business Name"
                      />
                    </div>
                    <div class="col-lg-5">
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.address"
                              name="business_address"
                              rules="required"
                              label="Address"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.city"
                              name="business_city"
                              rules="required"
                              label="City"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row">
                        <div class="col-md-5">
                          <TextInput
                              v-model="business.state"
                              name="business_state"
                              rules="required"
                              label="State"
                          />
                        </div>
                        <div class="col-md-7">
                          <TextInput
                              v-model="business.zip"
                              name="business_zip"
                              rules="required"
                              label="Zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <label>Business Phone</label>
                          <VuePhoneNumberInput v-model="business.business_phone" valid-color="#e2e7f1" />
                        </div>
                        <div class="col-md-6 mb-sm-4">
                          <TextInput
                              v-model="business.business_website"
                              name="business_website"
                              label="Website URL"
                          ><span v-if="business.business_website"><a :href="business.business_website" target="_blank" class="btn btn-dark-blue link-target align-middle"><Feather class="link-eye" type="eye" /></a></span></TextInput>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.front_date"
                                name="date"
                                label="Date Created"
                                disabled
                            />

                          </div>
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.primary_category"
                                name="origin"
                                label="Primary Category"
                                disabled
                                class="bg-white"
                            />
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row btn-links">
                        <div v-if="business.place_id" class="col-md-6 mb-sm-3">
                          <label class="d-block">Review Link</label>
                          <a class="btn btn-dark-blue form-control" target="_blank" :href="reviewLink"><span class="d-inline-block align-top">View Business Review Link</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                        </div>
                        <div class="col-sm-6">
                          <div v-if="business.place_id">
                            <label class="d-block">Maps Link</label>
                            <a class="btn btn-dark-blue form-control" target="_blank" :href="business.google_maps_url"><span class="d-inline-block align-top">View Business On Maps</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                          v-model="business.unique_id"
                          name="short_url_website"
                          label="Short URL Review Link"
                          linkstart="https://l.reviewour.biz/r/"
                      ><button type="button" class="btn btn-dark-blue link-target align-middle" @click="copyReviewLink(business.unique_id)"><Feather class="link-eye" type="copy" /></button></TextInput>
                    </div>
                  </div>
                  <div class="row row-cols-md-2 row-cols-xl-5 ">
                    <div class="col">
                      <TextInput
                          v-model="business.custom_field_1"
                          name="custom_field_1"
                          label="Custom Field 1"
                      />
                    </div>
                    <div class="col">
                      <TextInput
                          v-model="business.custom_field_2"
                          name="custom_field_2"
                          label="Custom Field 2"
                      />
                    </div>
                    <div class="col">
                      <TextInput
                          v-model="business.custom_field_3"
                          name="custom_field_3"
                          label="Custom Field 3"
                      />
                    </div>
                    <div class="col">
                      <TextInput
                          v-model="business.custom_field_4"
                          name="custom_field_4"
                          label="Custom Field 4"
                      />
                    </div>
                    <div class="col">
                      <TextInput
                          v-model="business.custom_field_5"
                          name="custom_field_5"
                          label="Custom Field 5"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="row">
                        <div class="col-md-6 col-xl-4">
                          <TextInput
                              v-model="business.first_name"
                              name="first_name"
                              label="First Name"
                          />
                        </div>
                        <div class="col-md-6 col-xl-4">
                          <TextInput
                              v-model="business.last_name"
                              name="last_name"
                              label="Last Name"
                          />
                        </div>
                        <div class="col-md-12 col-xl-4">
                          <label>Phone Number</label>
                          <VuePhoneNumberInput v-model="business.phone_number" :error="phoneError" valid-color="#e2e7f1" />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="row">
                        <div class="col-md-6 col-xl-7">
                          <TextInput
                              v-model="business.email"
                              name="owner.email"
                              rules="email"
                              label="Email"
                          />
                        </div>
                        <div class="col-md-6 col-xl-5 ">
                          <label>Status</label>
                          <input
                              v-model="business.status"
                              type="text"
                              :style="'color:' + statusColor(business.status)"
                              disabled
                              name="status"
                              class="form-control font-weight-bold"
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <b-form-group>
                    <div class="d-inline-flex align-items-center">
                      <div class="mr-3">
                        <router-link :to="{ name: 'agency.letter-template', params: { id: business.search ? business.search.letter_template_id : null } }" class="btn btn-dark-blue form-control" target="_blank"><span class="d-inline-block align-top">Letter Template</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></router-link>
                      </div>
                      <div class="mr-4">
                        <router-link :to="{ name: 'agency.le-landing-page', params: { id: business.search ? business.search.landing_page_id : null } }" class="btn btn-dark-blue form-control" target="_blank"><span class="d-inline-block align-top">Landing Page</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></router-link>
                      </div>
                      <div class="d-flex">
                        <div>{{business.rating}}</div>
                        <div><Feather :stroke-width="1" fill="gold" class="icon-xs" type="star" /></div>
                        <div>({{business.review_count > 0 ? business.review_count : 0}})</div>
                      </div>
                    </div>
                    <span v-if="(invalid && touched)"></span>
                    <b-button
                        variant="success"
                        class="float-right btn-sm"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
                <div v-if="business.reviews.length" class="row">
                  <div class="col rwg-app-single mt-2">
                    <Review :review="business.reviews[0]" :hide-posted-on-google="!business.reviews[0].hasOwnProperty('author_title')" />
                  </div>
                </div>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Vue from "vue";
import Review from "@components/Common/Review.vue";
export default {
  components:{
    Review,
    VuePhoneNumberInput
  },
  data() {
    return {
      countries: [{ key: 'US', label: 'United States of America' }, { key: 'CA', label: 'Canada' }],
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      loadingOptIn:false,
      updatingOptIn:false,
      phoneError:false,
      password: {
        password: '',
        password_confirmation: '',
      },
      open:false
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    onlyCountries() {
      return [ this.business.country ]
    },
    user() {
      return this.$store.getters['auth/user']
    },
    reviewLink(){
      return 'https://search.google.com/local/writereview?placeid='+this.business.place_id;
    }
  },
  mounted() {
    this.$nextTick();
    this.getBusiness();
  },

  methods: {
    copyReviewLink(uniqueId) {
      navigator.clipboard.writeText('https://l.reviewour.biz/r/' + uniqueId);
      Vue.$toast.open({
        message: 'Link Copied',
        type: 'success',
        duration: 5000,
      })
    },
    updateBusiness() {
      this.loadingUpdate = true
      this.phoneError = false;
      this.$store
        .dispatch('leads/update', this.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          this.loadingUpdate = false
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
            if(Object.keys(err.response.data.errors).includes('phone_number')){
              this.phoneError = true;
            }
          }
        })
    },

    getBusiness() {
      this.loadingBusiness = true
      this.$store
        .dispatch('leads/find', this.$route.params.lead)
        .then((business) => {
          console.log(business);
          this.loadingBusiness = false;
          this.$nextTick();
          this.business = business
          const rwgReviews = {
              current_page:1,
              data: business.reviews,
              next_page_url:null
          };
          sessionStorage.setItem('rwg_' + business.place_id,JSON.stringify({
            place_id:business.place_id,
            business_name:business.business_name,
            rating:5,
            total_review:1,
            reviews:rwgReviews
          }));
          setTimeout(function(){
            document.documentElement.dispatchEvent(new CustomEvent('setupRWG'));
          }, 100);
        })
        .catch((resp) => {
          console.log(resp);
          // this.$router.push({ name: 'agency.businesses.index' })
          this.loadingBusiness = false
        }).finally(() => {
      })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import url('https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css');
  i.feather--eye{
    margin-top: -3px;
  }
  .btn-links .btn{
    white-space: nowrap;
   span{
      vertical-align: top;
    }
  }
  input[name="unique_id"] {
    max-width: 150px !important;
  }
  .copy-link, .link-target{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .link-target{
    max-height: 39px;
  }
  .business-reviews{
    border-top: 2px solid #ccc;
    padding-top: 20px;
  }
</style>
<style lang="scss">
.business-reviews{
  .rwg-app-example-slider{
    .rwg-title{
      display: none;
    }
    .rwg-review-header{
      display: none;
    }
  }
}
#MazPhoneNumberInput {
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
</style>
